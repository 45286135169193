<template>
  <div class="container p-tb-2">
    <h1>Orders history</h1>
    <div v-if="!loading && !!collection">
      <template v-if="collection.total">
        <div v-for="(order, i) in orders" class="tile order history-order" :key="i">
          <div class="meta">
            <div class="order-id">{{ order.superId }}</div>
            <div class="order-date">{{ order.getDate('mmm dd, yyyy, HH:MM') }}</div>
          </div>
          <div class="order-title">
            <router-link :to="`/orders/${order.superId}`" class="underlined"
              >{{ order.itemsAmount }} {{ pluralize('product', order.itemsAmount) }}
            </router-link>
          </div>
          <div class="order-status">{{ order.statusName }}</div>
        </div>
      </template>
      <div v-else>No one order created yet</div>
      <pager :total="collection.pagesAmount" :active="page" :on-navigate="navigate" />
    </div>
    <div v-if="loading">
      <template v-for="i in 5">
        <div class="tile order empty" :key="i" />
      </template>
    </div>
  </div>
</template>

<script>
import Pager from 'Components/Pager';
import { pluralize } from 'Lib/Utils';

export default {
  name: 'Orders',
  components: {
    Pager,
  },
  data() {
    return {
      collection: null,
      loading: true,
      itemsPerPage: 10,
      page: 1,
    };
  },
  computed: {
    /**
     * @returns {Order[]}
     */
    orders() {
      return this.collection.pageItems;
    },
    orderStatuses() {
      return this.$store.state.orderStatuses;
    },
  },
  methods: {
    pluralize,
    load() {
      this.setLoading(true);
      this.$api
        .getOrdersCollection({
          itemsPerPage: this.itemsPerPage,
          page: this.page,
        })
        .then(collection => {
          this.collection = collection;
          this.setLoading(false);
        })
        .catch(this.onError);
    },
    navigate(page) {
      this.page = page;
      this.load();
    },
    onError() {
      this.setLoading(false);
    },
    setLoading(state) {
      this.loading = state;
      this.$store.dispatch('loader', state);
    },
  },
  created() {
    this.load();
  },
};
</script>

<style type="text/css" lang="scss">
.order {
  @mixin muted {
    color: #878b8b;
  }
  color: #5a5a5a;
  font-weight: 500;
  padding: 25px;
  &-id {
    position: relative;
    padding-right: 10px;
    margin-right: 10px;

    &:before {
      @include muted;
      content: '# ';
    }
  }
  &-title {
    flex-grow: 2;
    padding-left: 5rem;
    color: #454545;
    font-size: 18px;
    font-weight: 400;
  }
  &-qty {
    width: 100px;

    &:before {
      @include muted;
      content: 'Qty: ';
    }
  }
  &-date {
    width: 150px;
    font-weight: 400;
    margin-top: 5px;
    font-size: 12px;
  }
  &-status {
    width: 150px;
    text-align: center;
    padding: 5px;
    border-radius: 3px;
    text-align: center;
  }
}
</style>
